import React, { useEffect } from 'react';
import { languagesHelper as language } from '../../helpers/languages';
import css from './TypeformEmbed.module.css';

export const TypeformEmbed = ({ isVisible, formType }) => {
  // Get current language from the helper
  const currentLocale = language.get();

  // Get the form ID based on current language and form type
  const getLocalizedFormId = () => {
    const formId = process.env[`REACT_APP_TYPEFORM_ID_${formType}_${currentLocale.toUpperCase()}`];
    if (!formId) {
      console.warn(
        `Form ID for ${formType} in ${currentLocale} is not defined. Feedback form will not be displayed.`
      );
      return null;
    }
    return formId;
  };

  useEffect(() => {
    if (isVisible) {
      const script = document.createElement('script');
      script.src = '//embed.typeform.com/next/embed.js';
      script.async = true;
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [isVisible]);

  if (!isVisible || !getLocalizedFormId()) return null;

  return (
    <div className={css.feedback}>
      <div data-tf-live={getLocalizedFormId()}></div>
    </div>
  );
};
