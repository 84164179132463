import React from 'react';
import { Modal } from '../../../components';
import PropTypes from 'prop-types';
import OfferForm from './OfferForm';
import moment from 'moment';
import { createResourceLocatorString } from '../../../util/routes';
import { OFFER_CONSTANTS, OPEN_OFFER_MODAL } from '../../../util/enums';
import { languagesHelper as language } from '../../../helpers/languages';
import { currencyOptions } from '../../../util/currency';
function OfferModal(props) {
  const {
    onManageDisableScrolling,
    onOpenOffersModal,
    isOffersModalOpen,
    intl,
    config,
    history,
    routeConfiguration,
    price,
    listingTitle,
    authorDisplayName,
    listing,
    currentUser,
    sendInquiryInProgress,
    onSendInquiry,
    selectedVariant,
    location,
    listingBaseParams,
    conversionRate,
    currentCurrency,
    providerCurrency,
    conversionRatesOffer,
  } = props;

  const shouldOpenOfferModal = location?.hash === OPEN_OFFER_MODAL;

  let currentLocale = language.get();
  let currentCurrencyLabel = currencyOptions?.find(c => c.key === currentCurrency)?.label;

  let providerCurrencyLabel = currencyOptions?.find(c => c.key === providerCurrency)?.label;
  const providerRate = conversionRatesOffer?.[providerCurrency] || 1;

  const onSendOfferRequest = async values => {
    const { price } = values;

    const offerPrice = Number(price?.amount / 100).toFixed(2);
    const offerPriceConverted = Number((price?.amount * providerRate) / 100).toFixed(2);

    try {
      await onSendInquiry(
        selectedVariant,
        intl.formatMessage(
          { id: 'OfferModal.sendOffferMessage' },
          { offerPrice, currency: providerCurrencyLabel }
        ),
        {
          offerPrice,
          offerPriceConverted,
          authorId: currentUser?.id?.uuid,
          createdAt: moment().unix(),
          type: OFFER_CONSTANTS.INITIAL,
          variant: selectedVariant,
          currentLocale,
          currentUser,
          currency: currentCurrency,
          listingCurrency: price.currency,
          currencyLabel: currentCurrencyLabel,
          providerCurrencyLabel: providerCurrencyLabel,
          conversionRate,
        }
      );
      history.push(createResourceLocatorString('OffersPage', routeConfiguration, {}, {}));
    } catch (error) {
      onOpenOffersModal(false);
    }
  };

  return (
    <div>
      <Modal
        id="OfferModal"
        isOpen={!!isOffersModalOpen || shouldOpenOfferModal}
        onClose={() => {
          onOpenOffersModal(false);
          if (shouldOpenOfferModal) {
            history.push(listingBaseParams);
          }
        }}
        onManageDisableScrolling={onManageDisableScrolling}
        usePortal
      >
        <OfferForm
          onSubmit={onSendOfferRequest}
          config={config}
          price={price}
          listingTitle={listingTitle}
          inquiryInProgress={sendInquiryInProgress}
          authorDisplayName={authorDisplayName}
          conversionRate={conversionRate}
          currentCurrency={currentCurrency}
        />
      </Modal>
    </div>
  );
}

OfferModal.propTypes = {
  onManageDisableScrolling: PropTypes.func.isRequired,
  onOpenOffersModal: PropTypes.func.isRequired,
  isOffersModalOpen: PropTypes.bool.isRequired,
  intl: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  routeConfiguration: PropTypes.array.isRequired,
  listingTitle: PropTypes.string.isRequired,
  authorDisplayName: PropTypes.string.isRequired,
  listing: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  sendInquiryInProgress: PropTypes.bool.isRequired,
  onSendInquiry: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  listingBaseParams: PropTypes.string.isRequired,
};

export default OfferModal;
