import React from 'react';
import { string, bool } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import { Form, PrimaryButton, FieldCurrencyInput, Icons } from '../../../components';
import appSettings from '../../../config/settings';
import { getPriceValidators } from '../../../util/validators';
import { BASE_CURRENCY, BASE_CURRENCY_SYMBOL, LISTING_MIN_PRICE } from '../../../util/enums';

import { currencyOptions } from '../../../util/currency';

import css from './Offers.module.css';
import { calculatePercentage } from '../../../util/genericHelpers';

import { formatMoney } from '../../../util/currency';
import { types as sdkTypes } from '../../../util/sdkLoader';

const { Money } = sdkTypes;

const calculatePlaceholderPrice = (price, conversionRate, isOtherCurrency, percentage = 90) => {
  if (!price || isNaN(conversionRate)) return 0;

  const baseAmount = price.amount * conversionRate;
  const roundedAmount = isOtherCurrency ? Math.ceil(baseAmount / 100) * 100 : baseAmount;
  return calculatePercentage(roundedAmount / 100, percentage);
};

const OfferFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        handleSubmit,
        intl,
        price,
        listingTitle,
        authorDisplayName,
        sendEnquiryError,
        invalid,
        inquiryInProgress,
        form,
        isCounterOffer,
        conversionRate,
        currentCurrency,
      } = fieldRenderProps;

      const classes = classNames(rootClassName || css.root, className);

      const priceValidators = getPriceValidators(LISTING_MIN_PRICE, BASE_CURRENCY, intl);

      const matomoEvent = amount => {
        // Matomo
        if (typeof _paq !== 'undefined') {
          _paq.push(['trackEvent', 'Shopping', 'ShopMakeOffer', amount]);
        }
      };

      const customHandleSubmit = async event => {
        const values = form.getState().values;
        const amount = values.price.amount / 100;
        matomoEvent(amount);
        handleSubmit(event);
      };

      let currentCurrencyLabel = currencyOptions?.find(c => c.key === currentCurrency)?.label;

      const currencySymbol = currentCurrencyLabel || BASE_CURRENCY_SYMBOL;
      const currencyBase = currentCurrency || BASE_CURRENCY;
      const otherCurrency = currentCurrency !== price?.currency && !!conversionRate;

      const placeholderPrice = calculatePlaceholderPrice(price, conversionRate, otherCurrency);

      const placeholderMoney = new Money(
        Math.round(Math.max(0, placeholderPrice) * 100),
        currencyBase
      );

      const placeholder = formatMoney(intl, placeholderMoney || new Money(0, currencyBase));

      return (
        <Form
          className={classes}
          onSubmit={customHandleSubmit}
          enforcePagePreloadFor="OrderDetailsPage"
        >
          {!isCounterOffer && (
            <div className={css.offerFormHead}>
              <Icons name="offersIcon" />
              <h2 className={css.heading}>
                <FormattedMessage
                  id="OfferForm.heading"
                  values={{ listingTitle, authorDisplayName }}
                />
              </h2>
              <p className={css.description}>
                <FormattedMessage id="OfferForm.description" />
              </p>
            </div>
          )}
          <FieldCurrencyInput
            id="price"
            name="price"
            className={css.input}
            placeholder={placeholder}
            currencyConfig={appSettings.getCurrencyFormatting(currencyBase)}
            validate={priceValidators}
          />
          <div className={css.actionBtns}>
            {sendEnquiryError ? (
              <p className={css.error}>
                <FormattedMessage id="OfferForm.sendEnquiryError" />
              </p>
            ) : null}
            <PrimaryButton
              className={css.submitBtn}
              type="submit"
              inProgress={inquiryInProgress}
              disabled={invalid}
            >
              <FormattedMessage id="OfferForm.submitButtonText" />
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

OfferFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  inProgress: false,
  sendEnquiryError: null,
};

OfferFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  inProgress: bool,

  listingTitle: string.isRequired,
  authorDisplayName: string.isRequired,
  sendEnquiryError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,
};

const OfferForm = compose(injectIntl)(OfferFormComponent);

OfferForm.displayName = 'OfferForm';

export default OfferForm;
